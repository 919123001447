import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fleetData, robotData } from '../Data/FleetData'
import DashboardSideBar from '../subcomponents/DashboardSideBar'
import Header from './Header'
import SignalCellularAltSharpIcon from '@mui/icons-material/SignalCellularAltSharp';
import Map from '../subcomponents/Map'
import { useLocation, useNavigate } from 'react-router-dom'
import { fleetlist } from '../API'
import { useSelector } from 'react-redux'
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import Carousel from '../subcomponents/Courosel'
import GoogleTwo from '../subcomponents/GoogleTwo.jsx'


function Dashboard(props) {

    const [mapcheck, setmapcheck] =useState(false)
    const [mapchecktwo, setmapchecktwo] =useState(false)
    const logindata= useLocation()
    const [fleetlistData, setfleetlist] =useState()
    const emailId =localStorage.getItem('useremail')
    let activerobot=0
    let robotlength=0
    const navigate =useNavigate()
    const token =localStorage.getItem('token')

        const options={
        loop: true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    }
 useEffect(()=>{
    fleetlist(emailId ,token).then((res)=>{
         console.log('Fleet List response', res)
         setfleetlist(res.data.fleet)
    }).catch((err)=>{
        console.log('Fleet List error', err)
    })
    fleetlistData?.map((item)=>{
      return  item?.robots?.map(itm=>{
               return   robotlength++
        }) 
    })

   
  
 },[activerobot]) 

 const getrobotStatus=(status)=>{
   switch(status){
    case "UNAVAILABLE":
        return <p className='color-red Dashboard_page_robotStatusClass'> Unavailable</p>
    case "AVAILABLE":
        return <p className='color-green Dashboard_page_robotStatusClass'> Available</p>
    case "ERROR":
        return <p className='color-yellow Dashboard_page_robotStatusClass'> Error</p>     
    default:
        return <p className='color-red Dashboard_page_robotStatusClass'> {status}</p>       
    }
}
const getrobotDot=(status)=>{
  switch(status){
    case "UNAVAILABLE":
        return <div className='red-dot'> </div>
    case "AVAILABLE":
        return <div className='green-dot'> </div>    
    case "ERROR":
        return <div className='yellow-dot'> </div> 
    default:
        return <div className='red-dot'> </div>
    }
}
const getrobotIcons=(item)=>{
    switch(item.robotStatus){
        case "UNAVAILABLE":
            return<>
                   <img src="/assets/images/CPUred.svg"/>
                   <img src="/assets/images/Batteryred.svg"/> <> {parseFloat(item.robotBattery).toFixed(1)} %</> 
                   <SignalCellularAltSharpIcon/>
                   <img src="/assets/images/Bagorange.svg"/>
                   </>   
        case "AVAILABLE":
            return <>
            <img src="/assets/images/CPUYellow.svg"/>
            <img src="/assets/images/Batteryred.svg"/><> {parseFloat(item.robotBattery).toFixed(1)} %</>
            <SignalCellularAltSharpIcon/>
            <img src="/assets/images/Bagyellow.svg"/>
            </>     
        case "ERROR":
            return <>
            <img src="/assets/images/CPUorange.svg"/>
            <img src="/assets/images/Batteryred.svg"/><> {parseFloat(item.robotBattery).toFixed(1)} %</>
            <SignalCellularAltSharpIcon/>
            <img src="/assets/images/Bagred.svg"/>
            </> 
        default:
            return <>
            <img src="/assets/images/CPUred.svg"/>
            <img src="/assets/images/Batteryred.svg"/><> {parseFloat(item.robotBattery).toFixed(1)} %</>
            <SignalCellularAltSharpIcon/>
            <img src="/assets/images/Bagred.svg"/>
            </> 
    }
}
const isopen = useSelector(state=>state.dashboardopenReducer)

            const handlerobotClick=(itm)=>{
                let latitude
                let longitude
                fleetlistData.map((ttt)=>{
                if(ttt.fleetId === itm.fleetId ){
                    ttt.robots.map((itmmm)=>{ 
                            if(itmmm.robotId ===itm.robotId){
                                latitude = itmmm.latitude
                                longitude= itmmm.longitude
       }})
    }}) 
                navigate(`/robotPage/${itm.robotId}`, {state:{data:itm , latitude:latitude ,longitude:longitude,robotlistData:fleetlistData}})
}

const handleFleetClick=(fleetData)=>{
    navigate(`/fleetPage/${fleetData.fleetName}`, {state:{data:fleetData}})
 }
    return (
     <>
     {/* {console.log('fleetlistData',fleetlistData)} */}
      <Header />
      <div className="Dashboard_page_Whole_content_wrapper_main"> 
             <DashboardSideBar dashboardopen={props.dashboardopen} active="dashboard"/>
         <div className={isopen.userlogindata ? 'Dashboard_page_right_side margin_left_close':'Dashboard_page_right_side margin_left'}>
        {(()=>{
            fleetlistData?.map((item)=>{
                return item.robots.map((itm)=>{
                    if(itm.robotStatus==="AVAILABLE"){
                        return activerobot++
                    }
                })
            })
        })()} 
           <div className="Dashboard_page_rightSide_content_heading"> 
           {mapcheck ? 
                  <div>
                  <FormGroup style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                 <span className={mapchecktwo ?'Dashboard_page_rightSide_content_heading_span switchcase-active':null } style={{paddingRight: '20px'}}> Fleets Locations</span>
                   <FormControlLabel style={{color:"#fff" }} control={<Switch checked={mapchecktwo} style={{color:"#fff" }} onChange={()=>{setmapchecktwo(!mapchecktwo)}} />} label="" />
                    <span className={mapchecktwo? null:'switchcase-active'}> Robots locations </span> 
                  </FormGroup> 
                 
                </div> :<h4> My Fleets</h4>}
               <FormGroup className="Map_view_select_box" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                 <span className={mapcheck ?'Dashboard_page_rightSide_content_heading_span switchcase-active':null } style={{paddingRight: '20px'}}> Cards View</span>
                   <FormControlLabel style={{color:"#fff" }} control={<Switch checked={mapcheck} style={{color:"#fff" }} onChange={()=>{setmapcheck(!mapcheck)}} />} label="" />
                 <span className={mapcheck? null:'switchcase-active'}> Map View </span> 
               </FormGroup> 
          </div> 
          {mapcheck ? 
        //   <Map fleetlistData={fleetlistData} mapchecktwo={mapchecktwo}/> 
        <GoogleTwo
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCKoNGE_OqEKkrZuyY45u7GqvCNzQ4wyT0&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '90vh' }} />}
        mapElement={<div style={{ height: '90vh' }}/>}
        fleetlistData={fleetlistData} mapchecktwo={mapchecktwo} />
          :
        <>
        <div className='Dashboard_page_fleets_list_wrapper'>
         
         {fleetlistData?.length >3? <OwlCarousel items={fleetlistData?.length}  
                            className="owl-theme"  
                            loop 
                            dots={true}
                            nav={true}
                            center={true}
                            options={options}>           
              {fleetlistData?.map(item=>{
                 return <div className='Dasboard_page_individual_fleet_box_wrapper' onClick={()=>handleFleetClick(item)}> 
                        <h4 className={activerobot > 0 ? 'Dashboard_page_individual_fleet_Heading_Green_h4':'Dashboard_page_individual_fleet_Heading_red_h4'}> {item.fleetName}</h4> 
                        {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Client </p><p>{item.clientName}</p> </div> */}
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Location </p><p>{item.location}</p> </div>
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Robots </p><p>{item.robots.length}</p> </div>
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> GPS Location </p><p>{parseFloat(item.latitude).toFixed(2)} ,{parseFloat(item.longitude).toFixed(2)}</p> </div>
                        {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Orders in last 7 days </p><p>{item.totalOrders}</p> </div> */}
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Status </p><p>{item.status}</p> </div>
                      </div>
              })}
           
         </OwlCarousel>:<> 
            
         {fleetlistData?.map(item=>{
                 return <div className='Dasboard_page_individual_fleet_box_wrapper'onClick={()=>handleFleetClick(item)}> 
                        <h4 className={activerobot > 0 ?'Dashboard_page_individual_fleet_Heading_Green_h4':'Dashboard_page_individual_fleet_Heading_red_h4'}> {item.fleetName}</h4> 
                        {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Client </p><p>{item.clientName}</p> </div> */}
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Location </p><p>{item.location}</p> </div>
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Robots </p><p>{item.robots.length}</p> </div>
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> GPS Location </p><p>{parseFloat(item.latitude).toFixed(3)} ,{parseFloat(item.longitude).toFixed(3)}</p> </div>
                        {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Orders in last 7 days </p><p>{item.totalOrders}</p> </div> */}
                        <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Status </p><p>{item.status}</p> </div>
                      </div>
              })}</>}
      

         </div>    
    
         <h4 className='Dashboard_page_Robot_Card_heading'>  My Robots</h4>
         {/* {console.log('fleetlistData?.robots?',fleetlistData?.robots)} */}
         
     <div className='Dashboard_page_fleets_list_wrapper_two'>    
         
         
         {robotlength > 3 ? 
               <OwlCarousel items={3}  
                               className="owl-theme"  
                               loop  
                               dots={true}
                               nav={true}
                               center={true}
                               options={options}> 
            {fleetlistData?.map((item)=>{
                return  item?.robots?.map(itm=>{
                   return <div className='Dasboard_page_individual_robot_box_wrapper' onClick={()=>handlerobotClick(itm)}> 
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                                <div>
                                <h4 className='Dasboard_page_individual_robot_h4'> {itm.robotId}</h4> 
                                {getrobotStatus(itm.robotStatus)} 
                                </div>
                                {getrobotDot(itm.robotStatus)} 
                            
                            </div>
                            <div className='Dashboard_page_robot_div_icons'> 
                                {getrobotIcons(itm.robotStatus)}
                                {parseFloat(itm.currentSpeed).toFixed(2)} m/sec
                            </div> 
                           <div className='Dashboard_page_individual_robot_robotstatus_div'> 
                           {itm.robotRunningState}
                            </div>    
                    </div>
                }) })}
            </OwlCarousel> : 
                 <>{fleetlistData?.map((item)=>{
                    return  item?.robots?.map(itm=>{
                       return <div className='Dasboard_page_individual_robot_box_wrapper' onClick={()=>handlerobotClick(itm)}> 
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                                    <div>
                                    <h4 className='Dasboard_page_individual_robot_h4'> {itm.robotId}</h4> 
                                    {getrobotStatus(itm.robotStatus)} 
                                    </div>
                                    {getrobotDot(itm.robotStatus)} 
                               
                                </div>
                                <div className='Dashboard_page_robot_div_icons'> 
                                    {getrobotIcons(itm)}
                                    {parseFloat(itm.currentSpeed).toFixed(2)} m/sec
                                </div> 
                                <div className='Dashboard_page_individual_robot_robotstatus_div'> 
                                 {itm.robotRunningState}
                               </div>   
                           </div>
                    }) })}</>}

            </div>
            </>}   
                </div> 
      
      </div>
     </>
  )
}

export default Dashboard
