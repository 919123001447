import L from 'leaflet';

const iconPickup = new L.Icon({
    iconUrl: '/assets/images/MarkerOrange.svg',
    iconRetinaUrl:'/assets/images/MarkerOrange.svg',
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 45),
});

export { iconPickup };