import { createStore,combineReducers } from 'redux'
import { UserloginData,dashboardopenReducer,robotdatareducer,robotlistReducer,fleetlatitudeReducer,fleetlongitudeReducer,fleetIdreducer,robotMessageReducer,robotidReducer,sshendpointReducer } from './reducers';

const mutipleReducers = combineReducers({
    UserloginData,
    dashboardopenReducer,
    fleetIdreducer,
    robotdatareducer,
    robotMessageReducer,
    robotidReducer,
    robotlistReducer,
    sshendpointReducer,
    fleetlatitudeReducer,
    fleetlongitudeReducer
})

const store = createStore(mutipleReducers);

export default store