import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { addfleet, editfleet } from '../API';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditFleet(props) {
    const isopen = useSelector(state=>state.dashboardopenReducer)  
     const fleetData =useLocation()
     const toastmsg=(msg)=>toast(msg)
    const token =localStorage.getItem('token')
    const navigate =useNavigate()
    const validationSchema = yup.object({
         fleetName: yup
         .string()
         .required('Fleet Name is required'),
         location: yup
         .string()
         .required('Location is required'),
         latitude:yup.number('Latitude must be number or float').required('Latitude is required'),
         longitude:yup.number('Longitude must be number or float').required('Longitude is required')
     });
    const formik =useFormik({
        initialValues: {
          fleetName: fleetData.state.data.fleetName,
          location: fleetData.state.data.location,
          latitude:fleetData.state.data.latitude,
          longitude:fleetData.state.data.longitude
               },
        validationSchema:validationSchema,
        onSubmit: (values) => {
            editfleet(values, token ,fleetData.state.data._id)
            .then((res)=>{
                toastmsg(res.data.message)
                setTimeout(()=>{
                 navigate('/fleetPage')
                },2000)
            })
            .catch((err)=>{
                toastmsg(err.data.message)
            })
        }    
     })
    return (
    <>
    <Header/>
    {console.log('fleetData',fleetData)}
  <div className="Fleet_page_Whole_content_wrapper_main">
 
    <DashboardSideBar dashboardopen={props.dashboardopen} active="fleetpage"/>
     <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading"> <Link style={{color:'white', textDecoration:'none'}} to="/fleetpage">Fleets </Link>  <ArrowForwardIosIcon /> Edit Fleet </h4>
       <div className='AddFleet_page_content'> 
          <form onSubmit={formik.handleSubmit} >
            <div className="AddFleet_form_field_wrapper"> 
            
             
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.fleetName}
                    name="fleetName"
                    onChange={formik.handleChange}
                    placeholder='Fleet Name'
                    label="Fleet Name"
                    error={
                        formik.touched.fleetName &&
                        Boolean(formik.errors.fleetName)
                    }
                    helperText={
                        formik.touched.fleetName && formik.errors.fleetName
                    }
                    style={{margin:'15px'}}
                    className="Text_Field_Input_class"
                    />
         
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.location}
                    name="location"
                    onChange={formik.handleChange}
                    placeholder='Location'
                    label="Location"
                    error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                    }
                    helperText={
                        formik.touched.location && formik.errors.location
                    }
                    style={{margin:'15px'}}
                    className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.latitude}
                    name="latitude"
                    onChange={formik.handleChange}
                    placeholder='Latitude'
                    label="Latitude"
                    error={
                        formik.touched.latitude &&
                        Boolean(formik.errors.latitude)
                    }
                    helperText={
                        formik.touched.latitude && formik.errors.latitude
                    }
                    style={{margin:'15px'}}
                    className="Text_Field_Input_class"
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.longitude}
                    name="longitude"
                    onChange={formik.handleChange}
                    placeholder='Longitude'
                    label='Longitude'
                    error={
                        formik.touched.longitude &&
                        Boolean(formik.errors.longitude)
                    }
                    helperText={
                        formik.touched.longitude && formik.errors.longitude
                    }
                    style={{margin:'15px'}}
                    className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_page_save_button_wrapper"> 
            <button type="submit"> Save</button>
            </div>
         
        </form>   
       </div>
     </div>
     </div> 
     <ToastContainer/>
    </>
       )
}

export default EditFleet
