import L from 'leaflet';

const robotIcon = new L.Icon({
    iconUrl: '/assets/images/map-robot-icon.svg',
    iconRetinaUrl:'/assets/images/map-robot-icon.svg',
    iconAnchor: '/assets/images/map-robot-icon.svg',
    popupAnchor: '/assets/images/map-robot-icon.svg',
    shadowUrl: '/assets/images/map-robot-icon.svg',
    shadowSize: '/assets/images/map-robot-icon.svg',
    shadowAnchor: '/assets/images/map-robot-icon.svg',
    iconSize: new L.Point(30, 30),
});


export { robotIcon };