import React, { useEffect, useState } from 'react'

import { MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import { getrobotStatus, robotactionapi } from '../API';
import { iconMarker } from './IconMarker';
import { robotIcon } from './robotIcon';
function MapBoth(props) {
 
   let initiallat 
   let initiallong
   const token = localStorage.getItem('token')
   let mapheight = window.screen.height -220
  const [robotData, setrobotData] =useState() 
  const[commandlistdata, setcommandlistdata] =useState()
  useEffect(()=>{
    // getrobotStatus(props.robotId, token).then((res)=>{
    //       console.log("MapBoth GetRobot Status",res)
    //       setrobotData(res.data.data.robotStatus)
    // }).catch((err)=>{
    //   console.log(err)
    // })
    robotactionapi(props.robotId,token).then((res)=>{
      setcommandlistdata(res.data.robotCommandList)
      console.log('res.data.robotCommandList',res.data.robotCommandList)
     }).catch((err)=>{ 
     console.log(err)
    })
  },[])

  const data= [{}
    // {
    //   from_lat: "12.92415",
    //   from_long: "77.67229",
    //   id: "132512",
    //   to_lat: "12.92732",
    //   to_long: "77.63575",
    // },
    // {
    //   from_lat: "12.96691",
    //   from_long: "77.74935",
    //   id: "132513",
    //   to_lat: "12.92768",
    //   to_long: "77.62664",
    // }
]
let robotlatitude
let robotlongitude
let newdata=[]
let newdatatwo=[]

  return (

  
     <div className='Dashboard_page_Map_wrapper_Main_whole'>
          {console.log('props.robotlongitude' , props.robotlatitude,props.robotlongitude)}
              {(()=>{
                 {props?.fleetlistData?.map((item)=>{
                  initiallat = parseFloat(item.latitude)
                  initiallong = parseFloat(item.longitude)
                  })}
                  // robotlatitude =parseFloat(robotData?.latitude)
                  // robotlongitude = parseFloat(robotData?.longitude)
              })()}
         <MapContainer center={[initiallat,initiallong]} zoom={20} scrollWheelZoom={true} style={{height:mapheight}}>
            <TileLayer
                attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=rsMwQwEMIqx5LxUfoTyq"
            />

            <> 
                       <Marker position={[props.robotlatitude,props.robotlongitude]} icon={robotIcon} >
                      </Marker> 
                    {commandlistdata?.length >0?     
                   <Marker position={[commandlistdata[0]?.latitude,commandlistdata[0]?.longitude]} icon={robotIcon}>
                   </Marker>:null}
            
            </>
             {/* <> {props?.fleetlistData?.map((item)=>{
                   return item.robots.map((itm)=>{
                    let latitde = parseFloat(itm.latitude)
                    let logitde = parseFloat(itm.longitude)
                      data.push({latitude:latitde , longitude:logitde})
                      console.log('logitde',logitde,'latitde',latitde)  
                     if(itm.robotId===props.robotId){
                      return  <Marker position={[latitde,logitde]} icon={robotIcon} >
                      </Marker>
                     }
                   
                   })      
          })} </>   */}
     {/*   {props?.fleetlistData?.map((item)=>{
            let latitde = parseFloat(item.latitude)
            let logitde = parseFloat(item.longitude)
            return (
              <Marker position={[latitde,logitde]} icon={iconMarker} >
                <Popup>
              </Popup>
            </Marker> )
            })}  */}
            {/* {console.log('datadata',data)} */}
            {/* {(()=>{
               
                for(var i=0;i<data.length;i++){
                    newdata.push({
                         fromlat: data[i].latitude,
                         fromlon:data[i].longitude,
                         tolat: data[i+1]?.latitude,
                         tolon:data[i+1]?.longitude                   
                    })  
                }
                 newdatatwo =  newdata.filter((item)=> {
                    if(item.fromlat!==undefined && item.fromlon!==undefined && item.tolat!==undefined && item.tolon !== undefined)
                    {
                      return item
                    }
                  })

            })()} */}
            {/* {console.log('newdatatwo',newdatatwo)} */}
            {commandlistdata?.length >0? 
             <Polyline positions={[[commandlistdata[0]?.latitude, commandlistdata[0]?.longitude], [props.robotlatitude, props.robotlongitude]]} color={'#ff3300'} />
           :null}
         </MapContainer>
     </div>
  )
}

export default MapBoth
