import { useState } from "react";
import { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"
import { Polygon } from "react-google-maps";
import { robotIcon } from './robotIcon';

const flightPlanCoordinates = [
    { lat: -34.397, lng: 150.644 },
    { lat: 21.291, lng: -157.821 },
  ];

const token = localStorage.getItem('token')
const GoogleMapMain = withScriptjs(withGoogleMap((props) =>

<GoogleMap
  defaultZoom={8}
  defaultCenter={{ lat: props.robotlatitude, lng:  props.robotlongitude }}>
                 <Marker position={{lat:props.robotlatitude,lng:props.robotlongitude}}  icon={{
      url: '/assets/images/robot-icon-pickup.svg',
      scaledSize: new window.google.maps.Size(30, 30),
      }} >
        {console.log('props.commandlistdata[0]',props.commandlistdata[0])}
                    </Marker>
                     {props.commandlistdata?.length >0?     
                      <Marker position={{lat:parseFloat(props.commandlistdata[0]?.latitude),lng:parseFloat(props.commandlistdata[0]?.longitude)}}  icon={{
                        url: '/assets/images/robot-icon-dropoff.svg',
                        scaledSize: new window.google.maps.Size(30, 30),
                        }}>
                      </Marker>:null}    
                      {props.commandlistdata?.length >0? 
           <Polyline path={[{lat:parseFloat(props.commandlistdata[0]?.latitude), lng:parseFloat(props.commandlistdata[0]?.longitude)}, {lat: parseFloat(props.robotlatitude), lng:parseFloat(props.robotlongitude)}]} 
           geodesic={true}
           options={{
               strokeColor: "#ed716c",
               strokeOpacity: 0.75,
               strokeWeight: 2,
           }}
            />
         :null}
</GoogleMap>
))

export default GoogleMapMain