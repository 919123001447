export const fleetData=[
    {
        fleetName:'Sub_IGIA-T3_1',
        clientName:'Sub_IGIA-T3',
        area:'India, New Delhi',
        robotsCount:4,
        timezone:'UTC-4',
        orders7days:4,
        adminName:'Parthav_Sinha',
        dateCreated:'2022-11-11',
        robotStatus:'Unavailable',
        robotStatus:'Error',
    },
    {
        fleetName:'Sub_IGIA-T4_2',
        clientName:'Sub_IGIA-T4',
        area:'India, Pune',
        robotsCount:3,
        timezone:'UTC-4',
        orders7days:4,
        adminName:'Parthav_Sinha',
        dateCreated:'2022-11-11',
        robotStatus:'Available',
    },
    {
        fleetName:'Sub_IGIA-T5_3',
        clientName:'Sub_IGIA-T5',
        area:'India, Banglore',
        robotsCount:2,
        timezone:'UTC-4',
        orders7days:4,
        adminName:'Parthav_Sinha',
        dateCreated:'2022-11-11',
        robotStatus:'Unavailable',

    },

]
export const robotData=[
    {
        robotName:'RoV001',
        robotStatus:'Unavailable',
        robotSpeed:"0.5mls/sec"
    },
    {
        robotName:'RoV002',
        robotStatus:'Error',
        robotSpeed:"0.5mls/sec"
    },
    {
        robotName:'RoV003',
        robotStatus:'Available',
        robotSpeed:"0.5mls/sec"
    }
]