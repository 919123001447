import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { fleetData } from '../Data/FleetData'
import DashboardSideBar from './DashboardSideBar'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { fleetlist } from '../API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

function RobotPage(props) {
    const emailId = localStorage.getItem('useremail')
    const [robotlistData, setrobotlistData] =useState()
   const navigate =useNavigate()
   const toastmsg=(msg)=>toast(msg)    
   const token =localStorage.getItem('token')
    useEffect(()=>{
        fleetlist(emailId,token).then((res)=>{
            setrobotlistData(res.data.fleet)
          console.log('Robot page response',res)
        }).catch((err)=>{
              console.log('Robot page error',err)
        })
      },[])
    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
      ];
      const getrobotStatus=(status)=>{
        switch(status){
           case "AVAILABLE":
                   // return <img className="FleetPage_table_status_td_img" src="/assets/images/Stop.svg"/> 
                  // return <p style={{marginBottom:'0px'}}> Active</p>
                  return  <img src="/assets/images/Line-green.svg" />    
           case "UNAVAILABLE":
                // return <img src="/assets/images/Line 7.png"/> 
                // return <p style={{marginBottom:'0px'}}> Inactive</p>
                return  <img src="/assets/images/Line-red.svg" />  
           case "ERROR":
                // return <img src="/assets/images/Line 7.png"/> 
                return <p style={{marginBottom:'0px' , color:'yellow'}} > Error</p>
           default:
                // return <img src="/assets/images/Line 7.png"/>  
                return <p style={{marginBottom:'0px', color:'red'}}>{status}</p>
            }
    }
    const isopen = useSelector(state=>state.dashboardopenReducer)  
    const naviate =useNavigate()
    
    const handlerobotClick=(itm)=>{
        let latitude
        let longitude
        robotlistData.map((ttt)=>{
          if(ttt.fleetId === itm.fleetId ){
               ttt.robots.map((itmmm)=>{ 
                    if(itmmm.robotId ===itm.robotId){
                        latitude = itmmm.latitude
                        longitude= itmmm.longitude
                    }
               })
        }
        }) 
        navigate(`/robotPage/${itm.robotId}`, {state:{data:itm , latitude:latitude ,longitude:longitude,robotlistData:robotlistData}})
    }
    const handleaddrobot=()=>{
      naviate('/addrobot')
    }

    const handleRobotEdit=(itm)=>{
      navigate(`/editrobot/${itm.robotId}`,{state:{data:robotlistData}})
    }
    return (
  <>
        <Header/>

 <div className="Fleet_page_Whole_content_wrapper_main"> 
        {console.log('robotlistData',robotlistData)}
    <DashboardSideBar dashboardopen={props.dashboardopen} active="robotpage"/>
     <div className={isopen.userlogindata  ? "Dashboard_page_right_side_fleetpage margin_left_close":'Dashboard_page_right_side_fleetpage margin_left'}>
       <h4 className="Dashboard_page_Robot_Card_heading">Robots</h4>
        <table style={{width:'100%'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
             <th> Robot Id</th>
             <th> Robot Name </th>
             <th> End Point</th>
             <th> Date Created</th>
             <th> SSh End Point</th>
             <th> </th>
             <th> Status</th> 
            </tr>
           </thead>
          <tbody> 
     
         
            {robotlistData?.map((item)=>{
                return item?.robotsDetails?.map((itm)=>{
                    return (
                        <tr> 
                        <td> {itm.robotId}</td>
                        <td> {itm.displayRobotName}</td>
                        <td> {itm.endpoint}</td>
                        <td> {itm.createdAt}</td>
                        <td> {itm.sshEndpoint} </td> 
                        <td className="FleetPage_table_action_td">  
                        <img className="cursor_pointer" onClick={()=>handlerobotClick(itm)}  src="/assets/images/arrow-link-icon.svg"/>  
                        {/* <img className="cursor_pointer" src="/assets/images/b.svg" onClick={()=>handleRobotEdit(itm)}/> */}
                        <img className="cursor_pointer" src="/assets/images/Deleteiconlist.svg"/>
                        </td>
                        {item?.robots?.map((key)=>{
                            if(itm.robotId ==key.robotId){
                              console.log('itm.robotStatusitm.robotStatus',key.robotStatus)
                                return  <td className="FleetPage_table_status_td"> {getrobotStatus(key.robotStatus)}</td>
                            } })}
                      </tr>
                        )
                })
            })} 
            </tbody> 
        </table>
      </div>  
    </div>
         <button className="FleetPage_plusIcon" onClick={handleaddrobot}>
            <AddIcon/>
        </button> 
    {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon style={{}}/>}
        className="FleetPage_plusIcon"
       
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial > */}

    </>
  )
}

export default RobotPage
