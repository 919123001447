import { useState } from "react";
import { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"
import { Polygon } from "react-google-maps";
import Popupcard from "./Popupcard";
import { robotIcon } from './robotIcon';
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { OverlayView } from "react-google-maps"; 

const flightPlanCoordinates = [
    { lat: -34.397, lng: 150.644 },
    { lat: 21.291, lng: -157.821 },
  ];
  let initiallat
  let initiallong
  let isopn=true
  const handlemarkerpopup=()=>{
  
    console.log('sdvsfvb',isopn)
    if(isopn){
      isopn=false
    }
    else{
      isopn=true
    }
    
  }
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })
  
const token = localStorage.getItem('token')
const GoogleTwo = withScriptjs(withGoogleMap((props) =>

<>
{(()=>{
         {props?.fleetlistData?.map((item)=>{
          initiallat = parseFloat(item.latitude)
          initiallong = parseFloat(item.longitude)
      })}
   })()}

<GoogleMap
  defaultZoom={8}
  defaultCenter={{ lat:initiallat, lng:  initiallong }}>

   {console.log('propsprops',props)}
                      {props.mapchecktwo ?  <> {props?.fleetlistData?.map((item)=>{
                  return item.robots.map((itm)=>{
                     let latitde = parseFloat(itm.latitude)
                     let logitde = parseFloat(itm.longitude)
                    return  ( <MarkerWithLabel onClick={handlemarkerpopup} position={{lat:latitde,lng:logitde}} icon={{url: '/assets/images/map-robot-icon.svg',
                                    scaledSize: new window.google.maps.Size(30, 30),}}
                                    labelAnchor={new window.google.maps.Point(0, 0)}
                                    labelStyle={{}}
                                    >     
                          <OverlayView
                              position={{ lat: latitde, lng: logitde }}
                              mapPaneName={OverlayView.OVERLAY_LAYER}
                              getPixelPositionOffset={getPixelPositionOffset}
                          >
                          
                          {isopn ? <div style={{ background: `#efefef`, color: `black`, padding: 10, zIndex:'999999999' }}>
                                <p> Latitude: <span>{itm.latitude}</span></p>
                                <p> Longitude: <span>{itm.longitude}</span></p>
                                
                            </div>:<div> </div>  } 
                     </OverlayView> 
                            </MarkerWithLabel>
                    )
                   })   
                     
          })} </> :  <>  {props?.fleetlistData?.map((item)=>{
            let latitde = parseFloat(item.latitude)
            let logitde = parseFloat(item.longitude)
            return (<MarkerWithLabel position={{lat:latitde,lng:logitde}}  icon={{
              url: '/assets/images/map-robot-icon.svg',
              scaledSize: new window.google.maps.Size(30, 30),
              }}
              labelAnchor={new window.google.maps.Point(0, 0)}
              labelStyle={{}}
              >
            <OverlayView
                position={{ lat: 49.28590291211115, lng: -123.11248166065218 }}
                  mapPaneName={OverlayView.OVERLAY_LAYER}
                  getPixelPositionOffset={getPixelPositionOffset}
              >
               {isopn ? <div style={{ background: `#efefef`, color: `black`, padding: 10, zIndex:'999999999' }}>
                                <p> Latitude: <span>{item.latitude}</span></p>
                                <p> Longitude: <span>{item.longitude}</span></p>
                            </div>:<div></div>  }
        </OverlayView> 
          </MarkerWithLabel>)
  })} </>  }    
      
          
</GoogleMap>
</>
))

export default GoogleTwo