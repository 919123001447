import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { orderConfirm, orderlist } from '../API'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from 'react-toastify'

function OrderList(props) {
    const isopen = useSelector(state=>state.dashboardopenReducer)
    const navigate = useNavigate()
    const toastmsg=(msg)=>toast(msg)    

    const token=localStorage.getItem('token')
    const [orderlistData, setOrderlistData] =useState()
    const [refresh, setrefresh]=useState(false)
    const fleetData = useLocation()
    useEffect(()=>{
        orderlist(fleetData?.state?.fleetData?.fleetId, token).then((res)=>{
                   console.log('OrderList list response',res); 
                   setOrderlistData(res.data.orders)          
        }).catch((err)=>{
               console.log('OrderList list response',err); 
        })
    },[refresh])
    const handleAddOrder=()=>{
        navigate('/addorder')
      }   
    const handleconfirmorder=(orderId)=>{
        orderConfirm(orderId, token).then((res)=>{
            toastmsg(res.data.message)
            setrefresh(!refresh)
        }).catch((err)=>{
            toastmsg(err.data.message)
        }) 
    }  
   const getorderStatus=(item)=>{
       switch(item.statusCode){
        case 1:
            return <button className="OrderLIst_page_confirm_button" onClick={()=>handleconfirmorder(item.orderId)}> Confirm Order</button>
        case 2:
            return item.statusCode  
        default:
            return item.statusCode      
       }

   }    
  return (
    <>
    <Header/>
    {/* {console.log('fleetDatafleetData',fleetData)} */}
    <div className="Fleet_page_Whole_content_wrapper_main"> 
               <DashboardSideBar dashboardopen={props.dashboardopen} active="fleetpage"/>
      <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
        <h4 className="Dashboard_page_Robot_Card_heading">Orders</h4>
     
        <table style={{width:'100%'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
             <th> Time Stamp  </th>
             <th> RobotId </th>
             <th> OrderId </th>
             <th> Status</th>
             <th> StatusCode</th>
             <th style={{textAlign:'center',paddingLeft: '44px'}}> Delivery 
                <th> Lat</th>
                <th> Lon</th>
             </th>
             <th style={{textAlign:'center',paddingLeft: '44px'}}> PickUp 
                <th> Lat</th>
                <th> Lon</th>
             </th>
             <th style={{textAlign:'center'}}> Order Info
                <th> Size</th>
                <th> Dimensions</th>
             </th>
            </tr>
           </thead>
          <tbody> 
            {orderlistData?.map(item=>{
                return (
                <tr> 
                <td> {item.createdAt}UTC</td>
                <td> {item.robotId}</td>
                <td> {item.orderId}</td>
                <td style={{paddingLeft:'10px'}}> {item.orderStatus}</td>
                <td style={{padding: '0px',paddingLeft: '23px'}}> {getorderStatus(item)}</td>
                <td> 
                  <td style={{borderBottom:'none'}}>  {item.delivery.lat.toFixed(3)}</td>
                  <td style={{borderBottom:'none'}}>{item.delivery.lon.toFixed(3)} </td> 
                </td>
                <td>
                   <td style={{borderBottom:'none'}}> {item.pickup.lat.toFixed(3)} </td>
                   <td style={{borderBottom:'none'}}> {item.pickup.lon.toFixed(3)} </td>
                </td>
                <td> 
                  <td style={{borderBottom:'none',textAlign:'right'}}>  {item.orderInfo.size} </td>
                  <td style={{borderBottom:'none',textAlign:'right'}}>  {item.orderInfo.dimension.height} X {item.orderInfo.dimension.length} X {item.orderInfo.dimension.width} </td>
                  </td>
              </tr>
                )
            })}  
            </tbody> 
        </table>
      </div>
   
     </div>
     <button className="FleetPage_plusIcon" onClick={handleAddOrder}>
            <AddIcon/>
        </button>
      <ToastContainer/>  
        </>
  )
}

export default OrderList
