import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';

function EditRobot(props) {
    const isopen = useSelector(state=>state.dashboardopenReducer)  
    const robotData =useLocation()
    const validationSchema = yup.object({
         robotName: yup
         .string()
         .required('Robot Name is required'),
         location: yup
         .string()
         .required('Location is required'),
         latitude:yup.number('Latitude must be number or float').required('Latitude is required'),
         longitude:yup.number('Longitude must be number or float').required('Longitude is required')
     });
    const formik =useFormik({
        initialValues: {
          robotName: '',
          location: '',
          latitude:'',
          longitude:''        },
        validationSchema:validationSchema,
        onSubmit: (values) => {
        }    
     })
    return (
    <>
    <Header/>
  <div className="Fleet_page_Whole_content_wrapper_main">
     {console.log('robotData',robotData)}
     <DashboardSideBar dashboardopen={props.dashboardopen} active="robotpage"/>
     <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading"> Robots   <ArrowForwardIosIcon /> Add Robot </h4>
       <div className='AddFleet_page_content'> 
          <form onSubmit={formik.handleSubmit} >
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.robotName}
                    name="robotName"
                    onChange={formik.handleChange}
                    placeholder='Robot Name'
                    label='Robot Name'
                    error={
                        formik.touched.robotName &&
                        Boolean(formik.errors.robotName)
                    }
                    helperText={
                        formik.touched.robotName && formik.errors.robotName
                    }
                    style={{margin:'15px'}}
                    className="Text_Field_Input_class"
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.location}
                    name="location"
                    onChange={formik.handleChange}
                    placeholder='Location'
                    label='Location'
                    error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                    }
                    helperText={
                        formik.touched.location && formik.errors.location
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.latitude}
                    name="latitude"
                    onChange={formik.handleChange}
                    placeholder='Latitude'
                    label='Latitude'
                    error={
                        formik.touched.latitude &&
                        Boolean(formik.errors.latitude)
                    }
                    helperText={
                        formik.touched.latitude && formik.errors.latitude
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.longitude}
                    name="longitude"
                    onChange={formik.handleChange}
                    placeholder='Longitude'
                    label='Longitude'
                    error={
                        formik.touched.longitude &&
                        Boolean(formik.errors.longitude)
                    }
                    helperText={
                        formik.touched.longitude && formik.errors.longitude
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_page_save_button_wrapper"> 
            <button type="submit"> Save</button>
            </div>
         
        </form>   
       </div>
     </div>
     </div> 
    </>
       )
}

export default EditRobot
