import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { fleetData } from '../Data/FleetData'
import DashboardSideBar from './DashboardSideBar'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { Link, useNavigate } from 'react-router-dom';
import { deletefleet, fleetlist } from '../API';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from 'react-toastify';

function FleetPage(props) {
  const navigate =useNavigate()
  const emailId = localStorage.getItem('useremail')
  const [fleetlistData, setfleetlistData] =useState()
  const token =localStorage.getItem('token')
  const toastmsg=(msg)=>toast(msg)    

  useEffect(()=>{
    fleetlist(emailId,token).then((res)=>{
      setfleetlistData(res.data.fleet)
      console.log('Fleet page response',res)
    }).catch((err)=>{
          console.log('Fleet page error',err)
    })
  },[])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
      ];
    const getrobotStatus=(status)=>{
        switch(status){
           case "1":
                // return <img className="FleetPage_table_status_td_img" src="/assets/images/Stop.svg"/> 
                  // return <p style={{marginBottom:'0px'}}> Active</p>
                  return  <img src="/assets/images/Line-green.svg" />    
           case "2":
                // return <img src="/assets/images/Line 7.png"/> 
                // return <p style={{marginBottom:'0px'}}> Inactive</p>
                return  <img src="/assets/images/Line-red.svg" />  
           case "Error":
                // return <img src="/assets/images/Line 7.png"/> 
                return <p style={{marginBottom:'0px' , color:'yellow'}} > Error</p>
           default:
                // return <img src="/assets/images/Line 7.png"/>  
                return <p style={{marginBottom:'0px', color:'red'}}>{status}</p>
            }
    }

    const handleSingleFleet=(fleetData)=>{
       navigate(`/fleetPage/${fleetData.fleetName}`, {state:{data:fleetData}})
    }
    const isopen = useSelector(state=>state.dashboardopenReducer)  

    const handleAddFleet=()=>{
      navigate('/addfleet')
    }
    const handleFleetEdit=(item)=>{
     navigate(`/editfleet/${item._id}`,{state:{data:item}} )
    }
   const handledeleteFleet=(item)=>{
    setfleetlistData(fleetlistData.filter(itm =>itm._id !==item._id))
    deletefleet(item._id , token).then((res)=>{
        toastmsg(res.data.message)
        // console.log('Delete Fleet Response',res)
    }).catch((err)=>{
        toastmsg(err.data.message)
      //  console.log('Delete Fleet Error',err)
    })
   }
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
    return (
  <>
    <Header/>

   <div className="Fleet_page_Whole_content_wrapper_main"> 
             <DashboardSideBar dashboardopen={props.dashboardopen} active="fleetpage"/>
    <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading">Fleets</h4>
        <table style={{width:'100%'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
             <th> Fleet </th>
             <th> Area </th>
             <th> Latitude</th>
             <th> Longitude</th>
             <th> Date Created</th>
             <th> Robots</th>
             <th> </th>
             <th> Status</th> 
            </tr>
           </thead>
          <tbody> 
            {fleetlistData?.map(item=>{
                return (
                <tr> 
                <td> {item.fleetName}</td>
                <td> {item.location}</td>
                <td> {item.latitude}</td>
                <td> {item.longitude}</td>
                <td> {item.createdAt} UTC</td>
                <td> {item.robots.length} </td> 
                <td className="FleetPage_table_action_td">  
                 <img className="cursor_pointer" src="/assets/images/arrow-link-icon.svg" onClick={()=>handleSingleFleet(item)}/>
                <img className="cursor_pointer" src="/assets/images/b.svg" onClick={()=>handleFleetEdit(item)}/>
                <Button onClick={handleOpen}><img className="cursor_pointer" src="/assets/images/Deleteiconlist.svg" /></Button>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="Delete_modal_close_btn"> 
                    <p className='Modal-wrapper-p'>  Are you sure to want to delete the fleet ?</p>
                    <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                    </div>
                    <div className='Modal_content_button_wrapper'> 
                     <button onClick={handleClose} className="Modal-btn"> No </button>
                     <button onClick={()=>{
                        handledeleteFleet(item)
                        handleClose()
                    } }className="Modal-btn Modal-btn-red"> Yes</button>
                    </div>
                  </Box>
                </Modal>
                </td>
                <td className="FleetPage_table_status_td"> {getrobotStatus(item.status)}</td>
              </tr>
                )
            })}  
            </tbody> 
        </table>
      </div>  
    </div>
    <button className="FleetPage_plusIcon" onClick={handleAddFleet}>
            <AddIcon/>
        </button> 
    {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon style={{}}/>}
        className="FleetPage_plusIcon"
       
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial > */}
       <ToastContainer/>  
    </>
  )
}

export default FleetPage
