

const userlogindata={}
export const UserloginData=(state=userlogindata, action)=>{
    switch(action.type){
        case 'USER_LOGIN': 
        return{
            ...state,
            userlogindata:action.payload
        }   
        default:
            return state;    
    }

}

const dashboardopen=true
export const dashboardopenReducer=(state=dashboardopen, action)=>{
    switch(action.type){
        case 'OPEN': 
        return{
            ...state,
            userlogindata:true
        }   
        case 'CLOSE': 
        return{
            ...state,
            userlogindata:false
        }
        default:
            return state;    
    }

}
const fleetId=''
export const fleetIdreducer=(state=fleetId, action)=>{
    switch(action.type){
        case 'FETCH_FLEET_ID': 
        return{
            ...state,
            fleetId:action.payload
        }    
        default:
            return state;    
    }
}
const robotData={}
export const robotdatareducer=(state=robotData, action)=>{
    switch(action.type){
        case 'FETCH_ROBOT_DATA': 
        return{
            ...state,
            robotData:action.payload
        }    
        default:
            return state;    
    }
}
const robotmsgData=[]
export const robotMessageReducer=(state=robotmsgData, action)=>{
    switch(action.type){
        case 'FETCH_ROBOTMSG_DATA': 
        return{
            ...state,
            robotmsgData:action.payload
        }    
        default:
            return state;    
    }
}
const robotID=[]
export const robotidReducer=(state=robotID, action)=>{
    // console.log('RobotState', state,action.payload )
    switch(action.type){
        case 'GET_ROBOT_ID': 
        return{
            ...state,
            robotID:action.payload
        }    
        default:
            return state;    
    }
}

const robotlist=[]
export const robotlistReducer=(state=robotlist, action)=>{
    // console.log('RobotState', state,action.payload )
    switch(action.type){
        case 'FETCH_ROBOT_LIST_DATA': 
        return{
            ...state,
            robotlist:action.payload
        }    
        default:
            return state;    
    }
}

const sshendpoint=""
export const sshendpointReducer=(state=sshendpoint,action)=>{
    switch(action.type){
        case 'FETCH_SSH_ENDPOINT': 
        return{
            ...state,
            sshendpoint:action.payload
        }    
        default:
            return state;    
    }
}
const latitude=""
export const fleetlatitudeReducer=(state=latitude,action)=>{
    switch(action.type){
        case 'FETCH_FLEET_LATITUDE': 
        return{
            ...state,
            latitude:action.payload
        }    
        default:
            return state;    
    }
}
const longitude=""
export const fleetlongitudeReducer=(state=longitude,action)=>{
    switch(action.type){
        case 'FETCH__FLEET_LONGITUDE': 
        return{
            ...state,
            longitude:action.payload
        }    
        default:
            return state;    
    }
}