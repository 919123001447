import L from 'leaflet';

const iconPerson = new L.Icon({
    iconUrl: '/assets/images/robot-icon.svg',
    iconRetinaUrl:'/assets/images/robot-icon.svg',
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 45),
});

export { iconPerson };