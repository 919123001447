import { useSelect } from '@mui/base'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { dashboardcloseaction, dashboardopenaction } from '../redux/Actions'
import ListIcon from '@mui/icons-material/List';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


function DashboardSideBar(props) {

  const isopen = useSelector(state=>state.dashboardopenReducer)
  const dispatch= useDispatch()
  const [responsive, setresponsive] =useState() 
  const handledashboardwidthOpen=()=>{
    dispatch(dashboardopenaction(true))
  }
   const handledashboardwidthclose=()=>{
     dispatch(dashboardcloseaction(false)) 
   }
   const getsize=(res)=>{
     console.log('res',res)
   }
   useEffect(()=>{
     setresponsive(window.screen.width)
     getsize(window.screen.width)
    },[responsive])   
const role=localStorage.getItem('role')
 return (
    <>
    <div className={isopen.userlogindata || window.screen.innerWidth < 1024 ?'Dashboard_side_bar_wrapper_main_short':'Dashboard_side_bar_wrapper_main_long' }>
       <ul className={isopen.userlogindata || window.screen.innerWidth < 1024 ?'Dashboard_page_side_bar_close':' Dashboard_page_side_bar'}> 
       <Link to="/dashboard" style={{textDecoration:'none'}} className="Dashboard_side_bar_links">
         <li className={props.active==='dashboard'? 'SideBar_active': null}>
            <span><img className="Dashboard_SideBar_icon_one" src="/assets/images/Dashboard icon selected.svg"/> </span>
             <p style={{marginLeft:'20px', marginBottom:'0px'}} className="Dashboard_side_bar_P">Dashboard</p>     
        </li> </Link> 
        <Link to="/fleetPage" style={{textDecoration:'none'}} className="Dashboard_side_bar_links">  
        <li className={props.active==='fleetpage'? 'SideBar_active':null}> 
          <span style={{width:'25%'}}><img className="Dashboard_SideBar_icon" style={{width:'100%'}} src="/assets/images/Fleeticon.svg" /> </span>
          <p style={{marginLeft:'20px', marginBottom:'0px'}} className="Dashboard_side_bar_P"> Fleets</p>
        </li></Link> 
        <Link to="/robotpage" style={{textDecoration:'none'}} className="Dashboard_side_bar_links"> 
        <li className={props.active==='robotpage'? 'SideBar_active':null}> 
            <span style={{width:'25%'}}><img className="Dashboard_SideBar_icon" style={{width:'80%'}} src="/assets/images/Robot_icon_sidebar.svg"/></span>
           <p style={{marginLeft:'20px', marginBottom:'0px'}} className="Dashboard_side_bar_P"> Robots</p>
        </li>
        </Link>
        {/* {role ==='Admin' ? 
        <Link to="/adduser" style={{textDecoration:'none'}} className="Dashboard_side_bar_links"> 
        <li className={props.active==='adduser'? 'SideBar_active':null}> 
            <span style={{width:'25%'}}><img className="Dashboard_SideBar_icon" style={{width:'80%'}} src="/assets/images/robots.svg"/></span>
           <p style={{marginLeft:'20px', marginBottom:'0px'}}>  Add User</p>
        </li>
        </Link>
         : null} */}
        {role ==='Admin' ? 
        <Link to="/userlist" style={{textDecoration:'none'}} className="Dashboard_side_bar_links"> 
        <li className={props.active==='userlist'? 'SideBar_active':null}> 
            <span style={{width:'25%'}}><img className="Dashboard_SideBar_icon" style={{width:'80%'}} src="/assets/images/Client_icon.svg"/></span>
           <p style={{marginLeft:'20px', marginBottom:'0px'}} className="Dashboard_side_bar_P">Users</p>
        </li>
        </Link>
        : null}
        
        {/* <li> 
            <span><img className="Dashboard_SideBar_icon" src="/assets/images/clients-icon.svg"/> </span>
             Clients
        </li>
        <li>
            <span><img className="Dashboard_SideBar_icon" src="/assets/images/Role icon.svg"/> </span>
             Robot & Functions </li> */}
      </ul> 
        {/* {console.log('isopen', isopen)} */}
        {isopen.userlogindata ? <div style={{textAlign:'center'}}><button className="menu-bar-btn" onClick={handledashboardwidthclose}><ListIcon style={{fontSize:'30px'}}/></button></div>: <div style={{textAlign:'center'}}><button className="menu-bar-btn" onClick={handledashboardwidthOpen}> <MenuOpenIcon style={{fontSize:'30px'}}/> </button></div>}   
     </div>
    </>
  )
}

export default DashboardSideBar
