import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { fleetData } from '../Data/FleetData'
import DashboardSideBar from './DashboardSideBar'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { Link, useNavigate } from 'react-router-dom';
import { deleteuser, fleetlist, userlist } from '../API';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';


function UserList(props) {
  const navigate =useNavigate()
  const toastmsg=(msg)=>toast(msg)
  const emailId = localStorage.getItem('useremail')
  const [userListdata, setuserlistData] =useState()
       const token =localStorage.getItem('token')
  useEffect(()=>{
    userlist(token).then((res)=>{
        setuserlistData(res.data.users)
      console.log('User list response',res)
    }).catch((err)=>{
          console.log('User list error',err)
    })
  },[])

    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
      ];
    const getrobotStatus=(status)=>{
        switch(status){
           case "ACTIVE":
                return <img className="FleetPage_table_status_td_img" src="/assets/images/Stop.svg"/>        
           case "INACTIVE":
                return <img src="/assets/images/Line 7.png"/> 
           case "Error":
                return <img src="/assets/images/Line 7.png"/> 
           default:
                return <img src="/assets/images/Line 7.png"/>  
            }
    }

    const handleSingleFleet=(fleetData)=>{
       navigate(`/fleetPage/${fleetData.fleetName}`, {state:{data:fleetData}})
    }
    const isopen = useSelector(state=>state.dashboardopenReducer)  

    const handleAddFleet=()=>{
      navigate('/adduser')
    }
   const handleuserEdit=(item)=>{
    navigate(`/edituser/${item._id}`,{state:{data:item}} )
    }
   const handleuserdelete=(item)=>{
    setuserlistData(userListdata.filter(itm=> itm._id !== item._id))
    deleteuser(item._id , token).then((res)=>{
        toastmsg(res.data.message)
    }).catch((err)=>{
        toastmsg(err.data.message)
    })
   } 
   const handleUserClick=(item)=>{
  navigate(`/userlist/${item.emailId}` , {state:{data:item}})
   }
   const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
    return (
  <>
    <Header/>

   <div className="Fleet_page_Whole_content_wrapper_main"> 
             <DashboardSideBar dashboardopen={props.dashboardopen} active="userlist"/>
    <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading">User List</h4>
        <table style={{width:'100%'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
             <th> Role </th>
             <th> Name </th>
             <th> Email Address</th>
             <th> Pin</th>
             <th> Contact Number</th>
             <th> Action </th>
         
            </tr>
           </thead>
          <tbody> 
            {userListdata?.map(item=>{
                return (
                <tr> 
                <td> {item.role}</td>
                <td> {item.firstName?item.firstName:'N/A'}</td>
                <td> {item.username}</td>
                <td> {item.pin}</td>
                <td> {item.contactNumber} </td>
                <td className="FleetPage_table_action_td">  
                <img className="cursor_pointer" src="/assets/images/arrow-link-icon.svg"  onClick={()=>handleUserClick(item)}/>
                <img className="cursor_pointer" src="/assets/images/b.svg" onClick={()=>handleuserEdit(item)}/>
                <Button onClick={handleOpen}><img className="cursor_pointer" src="/assets/images/Deleteiconlist.svg" /></Button>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="Delete_modal_close_btn"> 
                    <p className='Modal-wrapper-p'>  Are you sure to want to delete the User ?</p>
                    <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                    </div>
                    <div className='Modal_content_button_wrapper'> 
                     <button onClick={handleClose} className="Modal-btn"> No </button>
                     <button onClick={()=>{
                        handleuserdelete(item)
                        handleClose()
                    } }className="Modal-btn Modal-btn-red"> Yes</button>
                    </div>
                  </Box>
                </Modal>
                </td>
              </tr>
                )
            })}  
            </tbody> 
        </table>
      </div>  
    </div>
    <button className="FleetPage_plusIcon" onClick={handleAddFleet}>
            <AddIcon/>
        </button> 
    {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon style={{}}/>}
        className="FleetPage_plusIcon"
       
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial > */}
    <ToastContainer/>
    </>
  )
}

export default UserList
