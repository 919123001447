import React, { useEffect } from "react";
import { useState } from "react";
import { ImStack } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Popupcard from "./Popupcard";
import DashboardSideBar from "./DashboardSideBar";
import '../App.css'
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { dashboardcloseaction, dashboardopenaction } from "../redux/Actions";
import Map from "./Map";
import { EmergencyStop, fleetlist, pauserobot } from "../API";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Battery80Icon from '@mui/icons-material/Battery80';
import GoogleTwo from "./GoogleTwo";


const IndividualFleet = (props) => {
   const navigate =useNavigate()
   const toastmsg=(msg)=>toast(msg) 
   const [mapchecktwo, setmapchecktwo] =useState(false)
   const fleetData =useLocation()
   let options = [
  {
    name: "Order ID",
    date: "2022-01-09",
    delivery: "Delivered at 16.17.45",
   
  },
  {
    name: "Order ID",
    date: "2022-01-09",
    delivery: "Delivered at 16.17.45",

  }

    ]
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [collapse, setCollapse] = useState(true);
  const [degree, setDegree] = useState(0);
  const [degreetwo, setdegreetwo] =useState(180)
  const [display, setDisplay] = useState("notdisplayed");
  const [displayone, setdisplayone] = useState(false)
  const [displaytwo, setdisplaytwo] =useState(false)
  const [displaythree, setdisplaythree] =useState(false)
  const [hidden, setHidden] = useState(true);
  const [hiddenL, setHiddenL] = useState(true);
  const [robotlistData, setrobotlistData] =useState()
  const dispatch =useDispatch()
  const handleCollapse = () => {
    setCollapse(!collapse);
    if(collapse){
        dispatch(dashboardopenaction(true)) 
    }
    else{
        dispatch(dashboardcloseaction(false))  
       }
    setDegree(collapse ? 180 : 0);
  };

  const emailId =localStorage.getItem('useremail')
  const token = localStorage.getItem('token')
  useEffect(()=>{
    fleetlist(emailId,token).then((res)=>{
        setrobotlistData(res.data.fleet)
      console.log('Robot page response',res)
    }).catch((err)=>{
          console.log('Robot page error',err)
    })
  },[])
  const showButton = () => {
    setDisplay("display");
  };

  const hideButton = () => {
    setDisplay("notdisplayed");
  };

  const handleChange = () => {
    setHidden(!hidden);
    setHiddenL(true)
    setDegree(hidden ? 0 : 180);
  };

  const handleChangeL = () => {
    setHiddenL(!hiddenL);
    setDegree(hiddenL ? 0 : 180);
  }
  const handleClick = (newPlacement, order) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    if(order==="one"){
      setdisplayone(true)
      setdisplaytwo(false)
      setdisplaythree(false)
     }
    else if(order==="two"){
      setdisplayone(false)
      setdisplaytwo(true)
      setdisplaythree(false)
    }
    else{
      setdisplaythree(true)
      setdisplaytwo(false)
      setdisplayone(false)
    }
    
  };
  const steps = [
    'Order Recieved',
    'Enroute to pickup',
    'Reached Pickup Location',
    "Waiting for Pickup",
    "Order Picked Up",
    "Enroute to Drop-off Location",
    'Reached Drop-off Location',
    'Waiting for Drop Off',
    'Order Delivered'

  ];

const fleetName=localStorage.getItem('SinglefleetName')
const isopen = useSelector(state=>state.dashboardopenReducer)

const handlerobotClick=(itm)=>{
    
  let latitude
  let longitude
  robotlistData.map((ttt)=>{
    if(ttt.fleetId === itm.fleetId ){
         ttt.robots.map((itmmm)=>{ 
              if(itmmm.robotId ===itm.robotId){
                  latitude = itmmm.latitude
                  longitude= itmmm.longitude
              }
         })
  }
  }) 
  navigate(`/robotPage/${itm.robotId}`, {state:{data:itm , latitude:latitude ,longitude:longitude,robotlistData:robotlistData}})
}
const handleOrderList=(fleetData)=>{
  navigate('/orderlist', {state:{fleetData}} )
}
const handlepausebtn=()=>{
  robotlistData.map(item=>{
       item.robots.map(itm=>{
        pauserobot(itm.robotId,token).then((res)=>{
         toastmsg(res.data.message)
     }).catch((err)=>{
         toastmsg(err.data.message)
     })
       })
  })
}
const handleStopButton=()=>{
  robotlistData.map((itm)=>{
       itm.robots.map((item)=>{
        EmergencyStop(item.robotId ,token).then((res)=>{
          toastmsg(res.data.message)
        }).catch((err)=>{
          toastmsg(err.data.message)
        })
       })
  })

}
return (
    <>
       <Header/>
    <div className="Fleet_page_Whole_content_wrapper_main">
     {console.log('robotlistDatarobotlistData',robotlistData)}
        <DashboardSideBar dashboardopen={props.dashboardopen} active="fleetpage"/>
        
         <div className={isopen.userlogindata? 'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left'}>
         <div className="FleetView-Page-Top-Content-wrapper"> 
           <div> 
              <p className="FleetView_page_haeding_tag"> Fleets  <ArrowForwardIosIcon style={{fontSize:'16px'}}/><span className="FleetView_page_Heading_fleetName"> {fleetData.state.data.fleetName}</span></p> 
           </div>
          <div className="FleetView_page_OrderList_pause_wrapper"> 
           <div className="FleetView_page_OrderList_content"> 
               <button style={{cursor:'pointer'}} onClick={()=>handleOrderList(fleetData.state.data)}> Orders</button>
           </div>
           <div className="FleetView_page_Play_Pause_wrapper"> 
               <div onClick={handlepausebtn} style={{cursor:'pointer'}}> 
                 <img src="/assets/images/pause.svg"/>
                 <p>Pause</p>
               </div>
              <div onClick={handleStopButton} style={{cursor:'pointer'}}> 
                 <img src="/assets/images/Stop.svg" className="stop-icon" alt="" />
                 <p>Stop</p>
             </div> 
           </div> 
        </div>
         </div> 
        {/* <div className="navContainer">
          <nav className="uppr">
            <h3> Client <span> {fleetName}</span>{" "} </h3>
            <h3> Area <span> India, New Delhi</span>{" "}  </h3>
            <h3> Robots <span> 4</span>{" "}  </h3>
            <h3> Time Zone <span> UTC-14</span> </h3>
            <h3> Date Ceated <span>20221228</span> </h3>
            <h3> Orders in last 7 days <span> 7</span> </h3>
            <h3> Admin <span> Name of the admin</span></h3>
          </nav>
          <img src="assets/images/CPUred.svg" alt="" />
        </div> */}


      <div className="FleetView_page_Image_Button_wrapper_wholeMain">    
        <div className="btn-grp">
          <Grid item style={{textAlign:"end"}}>
              <Button className="btn-top" onClick={handleClick('bottom-end','one')}>
                 Current Order{" "}
                 <span className="arrow-btn">
                    <img
                      src="/assets/images/Drop down arrow.svg"
                      onClick={handleChange}
                      style={{ transform: `rotate(${degree}deg)` }}
                      alt=""/>
                </span>
             </Button>
         </Grid>
        <Grid item style={{textAlign:"end"}}>
              <Button className="btn-top" onClick={handleClick('bottom-end','two')}>
                Last 10 Orders{" "}
                  <span className="arrow-btn">
                     <img
                       onClick={handleChangeL}
                       style={{ transform: `rotate(${degree}deg)` }}
                       src="/assets/images/Drop down arrow.svg"
                       alt=""
                    />
                  </span>
             </Button>
         </Grid>
          <Grid item style={{textAlign:"end"}}>
              <Button className="btn-top" onClick={handleClick('bottom-end','three')}>
                  All Orders{" "}
             </Button>
         </Grid>
         <Popper open={open} anchorEl={anchorEl} placement={placement} transition style={{zIndex:'9999999'}}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                  {displayone ?
                     <div className="FleetView_page_Popper_content_one_wrapper">
                     <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                        <h6> Order ID 00818</h6>
                        <p>  2022-01-29</p>
                        <p> 15.17.45</p> 
                     </div>
                    <div className="FleetView_page_Popper_content_one_wrapper_inner_two"> 
                     <Box sx={{ width: '100%' }}>
                      <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                         <Step style={{color:"red"}} key={label}>
                           <StepLabel >{label}</StepLabel>
                         </Step>
                       ))}
                     </Stepper>
                   </Box>
                    </div>
                     <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                       <h6> CABIN N</h6>
                       <div> <ArrowUpwardIcon style={{color:'green'}}/> PickUp: Aisle 1</div>
                       <div> <ArrowDownwardIcon style={{color:'red'}}/> Drop Off: Aisle 2</div>
                     </div>
                    </div>
                  : displaytwo ?
                     <div className="FleetView_page_Popper_content_two_wrapper">
                        <div className="FleetView_page_Popper_content_two"> 
                         <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                             <h6> Order ID 00818</h6>
                             <p>   2022-01-29</p>
                             <p> 15.17.45</p> 
                         </div>
                        <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                          <h6> CABIN N</h6>
                          <div> <ArrowUpwardIcon style={{color:'green'}}/> PickUp: Aisle 1</div>
                          <div> <ArrowDownwardIcon style={{color:'red'}}/> Drop Off: Aisle 2</div>
                       </div>
                      </div>  
                      <div className="FleetView_page_Popper_content_two"> 
                         <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                             <h6> Order ID 00818</h6>
                             <p>   2022-01-29</p>
                             <p> 15.17.45</p> 
                         </div>
                        <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                          <h6> CABIN N</h6>
                          <div> <ArrowUpwardIcon style={{color:'green'}}/> PickUp: Aisle 1</div>
                          <div> <ArrowDownwardIcon style={{color:'red'}}/> Drop Off: Aisle 2</div>
                       </div>
                      </div> 
                      <div className="FleetView_page_Popper_content_two"> 
                         <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                             <h6> Order ID 00818</h6>
                             <p>   2022-01-29</p>
                             <p> 15.17.45</p> 
                         </div>
                        <div className="FleetView_page_Popper_content_one_wrapper_inner-one"> 
                          <h6> CABIN N</h6>
                          <div> <ArrowUpwardIcon style={{color:'green'}}/> PickUp: Aisle 1</div>
                          <div> <ArrowDownwardIcon style={{color:'red'}}/> Drop Off: Aisle 2</div>
                       </div>
                      </div>                     
                    </div> :<>ddfbdf</>
                    }
                  </Paper>
                </Fade>
              )}
          </Popper> 
        </div>
          {/* <img
            className="fl-map"
            src="/assets/images/fleet-map.png"
            alt=""
          /> */}
          {console.log('fleetDatafleetData',fleetData)}
            <div className="Individual_fleet_page_map_wrapper">        
            {/* <FormGroup style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                 <span className={mapchecktwo ?'Dashboard_page_rightSide_content_heading_span switchcase-active':null } style={{paddingRight: '20px'}}> Fleets Location</span>
                   <FormControlLabel style={{color:"#fff" }} control={<Switch checked={mapchecktwo} style={{color:"#fff" }} onChange={()=>{setmapchecktwo(!mapchecktwo)}} />} label="" />
                    <span className={mapchecktwo? null:'switchcase-active'}> Robots locations </span> 
                  </FormGroup>  */}
                   <GoogleTwo
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCKoNGE_OqEKkrZuyY45u7GqvCNzQ4wyT0&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: '70vh' }} />}
                    mapElement={<div style={{ height: '70vh' }}/>}
                    fleetlistData={[fleetData.state.data]} mapchecktwo={true} />
             {/* <Map fleetlistData={[fleetData.state.data]} mapchecktwo={true}/> */}
           </div>
            {/* <Popupcard/> */}
          {/* <div className="two">
            {" "}
            <button
              className="cust-btn"
              onMouseEnter={(e) => showButton(e)}
              onMouseLeave={(e) => hideButton(e)}
            ></button>
            <div className={display}>
              <Fleetcard />
            </div>{" "}
          </div> */}
      {/* <div className="t-des" id={collapse ? '':'full-width'}>
          <table style={{transition:"0.5s ease-in-out" , borderRight:'0.5px solid #BCC4DF',width: 'auto' }} id={collapse ? '':'full-width'}>

         {fleetData.state.data.robots.map((item)=>{
                   return (
                    <tr style={{transition:"0.5s ease-in-out"}}>
                          <td>
                            <span className="red-alert" style={{cursor:'pointer'}} onClick={()=>handlerobotClick(item)}>{item.robotId}</span>{" "}
                          </td>
                   
                {!collapse ? (
                <>
             
                  <td>
                    <div className="new">
                
                     
                        <img
                          src="/assets/images/BlankCPU.svg"
                          alt=""
                        />
                      <p className="CPU-p-tag-fleetpage">
                        {((parseFloat(item.cpu1Usage) +parseFloat(item.cpu2Usage)+ parseFloat(item.cpu3Usage) + parseFloat(item.cpu4Usage))/4).toFixed(0)}
                       </p>
                    </div>
                  </td>

                  <td>
                    <div className="new">
                  
                      <span>
                        <img
                          src="/assets/images/Meterred.svg"
                          alt=""
                        />
                          {item.currentSpeed}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div className="new">
           
                      <span>
                        <img
                          src="/assets/images/DistanceLine.svg"
                          alt=""
                        />
                        {item.distanceTravelled.toFixed(2)} miles
                      </span>
                    </div>
                  </td>

                  <td>
                    <div className="new">
                    
                      <span>
                      <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'13px'}}/>
                        {parseInt(item.robotBattery)}%
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="new">
                      
                      <span>
                        <img
                          src="/assets/images/Reception max.svg"                          
                          alt=""
                        />
                      </span>
                    </div>
                  </td>
                  <tr>
                    <img
                      src="/assets/images/Line 486.svg"
                      alt=""
                    />
                  </tr>
                </>
              ) : null}
                    </tr> 
                  )})}
          </table>

          <img
            className="rot"
            src="/assets/images/Slide_left_arrow.svg"
            alt=""
            onClick={handleCollapse}
            style={{ transform: `rotate(${degree}deg)` , cursor:"pointer" , margin: '10px'}}
          />
        </div> */}
        <div> 
        <table style={{width:'96%',margin:'auto'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
             <th> RobotId  </th>
             <th> CPU Usage </th>
             <th> Speed </th>
             <th> Distance Travelled</th>
             <th> Battery</th>
             <th> Signal</th>
            </tr>
           </thead>
          <tbody> 
          {fleetData.state.data.robots.map((item)=>{
                   return (
                <tr> 
                <td style={{color:'#FF6767',cursor:'pointer'}}onClick={()=>handlerobotClick(item)}> {item.robotId}</td>
                <td> <div className="new">
                       <img  src="/assets/images/BlankCPU.svg"alt=""/>
                         <p className="CPU-p-tag-fleetpage">
                           {((parseFloat(item.cpu1Usage) +parseFloat(item.cpu2Usage)+ parseFloat(item.cpu3Usage) + parseFloat(item.cpu4Usage))/4).toFixed(0)}
                         </p></div></td>
                <td>  <img style={{margin:'10px', marginLeft:'0px'}} src="/assets/images/Meterred.svg"alt=""/>{parseFloat(item.currentSpeed).toFixed(2)} m/sec</td>
                <td>  <img style={{margin:'10px', marginLeft:'0px'}} src="/assets/images/DistanceLine.svg" alt=""/> {((item.distanceTravelled.toFixed(2)*1.60934)/20000).toFixed(2)} Km</td>
                <td style={{padding: '0px',paddingLeft: '23px'}}>   <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'24px'}}/> {parseInt(item.robotBattery)}%</td>
                 <td>  <img src="/assets/images/Reception max.svg" alt="" /></td>
              </tr>
                )
            })}  
            </tbody> 
        </table>
        </div>
     </div>

  
        <div className="map-icons ">
          <ImStack size={34} className="fill-square" />
          <AiOutlinePlus size={34} className="fill-circle" />
          <AiOutlineMinus size={34} className="fill-circle" />
        </div>
      </div>
      </div> 
      <ToastContainer/>
    </>
  );
};

export default IndividualFleet;
