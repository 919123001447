import React, { useEffect } from 'react'

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { iconMarker } from './IconMarker';
import { robotIcon } from './robotIcon';
import 'leaflet/dist/leaflet.css';

function Map(props) {
 
   let initiallat 
   let initiallong
  useEffect(()=>{
  
  })
  
  return (
     <div className='Dashboard_page_Map_wrapper_Main_whole'>
              {(()=>{
                 {props?.fleetlistData?.map((item)=>{
                  initiallat = parseFloat(item.latitude)
                  initiallong = parseFloat(item.longitude)
                  })}
              })()}
         <MapContainer center={[initiallat,initiallong]} zoom={15} scrollWheelZoom={true} style={{height:'500px'}}>
            <TileLayer
              attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
              url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=rsMwQwEMIqx5LxUfoTyq"
            />
            {props.mapchecktwo ?  <> {props?.fleetlistData?.map((item)=>{
                   return item.robots.map((itm)=>{
                    let latitde = parseFloat(itm.latitude)
                    let logitde = parseFloat(itm.longitude)
                    return  (<Marker position={[latitde,logitde]} icon={robotIcon}>
                    <Popup>
                    <p> Hello, Washington, DC</p>
                      </Popup>
                </Marker>)
                   })      
          })} </> :        <>  {props?.fleetlistData?.map((item)=>{
            let latitde = parseFloat(item.latitude)
            let logitde = parseFloat(item.longitude)
            return (
              <Marker position={[latitde,logitde]} icon={robotIcon}>
                 <Popup>
                  <p> Hello, Washington, DC</p>
                 </Popup>
            </Marker> )
  })} </>  }
               
      

         </MapContainer>
     </div>
  )
}

export default Map
