import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { InputLabel, TextField } from '@mui/material';
import { addfleet, addorder, addUser, orderConfirm } from '../API';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function AddOrder(props) {

    const addusererror=(msg)=> toast(msg)
    const addusersuccess=(msg)=>toast(msg)
    const [bookordermsg, setbookordermsg] =useState()
    const [isconfim, setisConfirm] =useState(false)
    const navigate =useNavigate()
    const isopen =useSelector(state=>state.dashboardopenReducer) 
    const token =localStorage.getItem('token')
    const validationSchema = yup.object({
         orderId:yup.string().required('Order Id is required'),
         pickuplat:yup.number().required('PickUp Latitude is required & must be number'),
         pickuplon:yup.number().required('PickUp Longitude is required & must be number'),   
         deliverylat: yup.number().required('Delivery Latitude is required & must be number'),
         deliverylon:yup.number().required('Delivery Longitude is required & must be number'),
         orderInfoSize:yup.string().required('Order Size Info is required'),
         height:yup.number('Height is required').required('Height is required'),
         length:yup.number('Length is required').required('Length is required'),
         width:yup.number('Width is required').required('Width is required'),
         volume:yup.number('Volume is required').required('Volume is required'),
         weight:yup.number('Weight is required').required('Weight is required'),
     });
     const toastmsg=(msg)=>toast(msg)  
    const formik =useFormik({
        initialValues: {
          orderId:'',
          pickuplat:'',
          pickuplon:'',
          deliverylat:'',
          deliverylon:'',
          orderInfoSize:'',
          height:'',
          length:'',
          width:'',
          volume:'',
          weight:''
               },
        validationSchema:validationSchema,
        onSubmit: (values) => {
            console.log('valuesvalues', values)
            addorder(values ,token).then((res)=>{
                // toastmsg(res.data.message)
                setbookordermsg(res.data.message)
                if(res.data.message==='order booked'){
                    setisConfirm(true)
                }
                else{
                    setisConfirm(false)
                }
            }).catch((err)=>{
                toastmsg(err.data.message)
            })
        }    
     })
    const handleconfirmorder=()=>{
        orderConfirm(formik.values.orderId, token).then((res)=>{
            toastmsg(res.data.message)
            if(res.status===200 || res.status==="200"){
               setTimeout(()=>{
                navigate('/orderlist')
               }, 2000) 
                
            }
        }).catch((err)=>{
            toastmsg(err.data.message)
        }) 
    } 
    return (
    <>
    <Header/>
  <div className="Fleet_page_Whole_content_wrapper_main">
 
    <DashboardSideBar dashboardopen={props.dashboardopen} active="fleetpage"/>
     <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading">   Add New Order </h4>
       <div className='AddFleet_page_content'> 
          <form onSubmit={formik.handleSubmit} style={{width:'80%'}}>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.orderId}
                    name="orderId"
                    onChange={formik.handleChange}
                    placeholder='OrderId'
                    error={
                        formik.touched.orderId &&
                        Boolean(formik.errors.orderId)
                    }
                    helperText={
                        formik.touched.orderId && formik.errors.orderId
                    }
                   style={{margin:'15px'}}
              />
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.orderInfoSize}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    name="orderInfoSize"
                    onChange={(event,newvalue)=>{
                        formik.setFieldValue('orderInfoSize',event.target.value)
                    }}
                    error={
                        formik.touched.orderInfoSize &&
                        Boolean(formik.errors.orderInfoSize)
                    }
                    helperText={
                        formik.touched.orderInfoSize && formik.errors.orderInfoSize
                    }
                   >
                    <MenuItem value=""> <em style={{color:"#BCC4DF", opacity:'0.5'}}>Size</em> </MenuItem>
                    <MenuItem value="M" defaultValue={true}>M</MenuItem>
                    <MenuItem value="L">L</MenuItem>
                    <MenuItem value="XL">XL</MenuItem>
             </Select>
             </FormControl>     
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.height}
                    name="height"
                    onChange={formik.handleChange}
                    placeholder="Height"
                    error={
                        formik.touched.height &&
                        Boolean(formik.errors.height)
                    }
                    helperText={
                        formik.touched.height && formik.errors.height
                    }
                    style={{margin:'15px'}}
                    />     
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.pickuplat}
                    name="pickuplat"
                    onChange={formik.handleChange}
                    placeholder='Pick Up Latitude'
                    error={
                        formik.touched.pickuplat &&
                        Boolean(formik.errors.pickuplat)
                    }
                    helperText={
                        formik.touched.pickuplat && formik.errors.pickuplat
                    }
                    style={{margin:'15px'}}
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.pickuplon}
                    name="pickuplon"
                    onChange={formik.handleChange}
                    placeholder='PickUp Longitude'
                    error={
                        formik.touched.pickuplon &&
                        Boolean(formik.errors.pickuplon)
                    }
                    helperText={
                        formik.touched.pickuplon && formik.errors.pickuplon
                    }
                    style={{margin:'15px'}}
                    />  
                      <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.length}
                    name="length"
                    onChange={formik.handleChange}
                    placeholder='Length'
                    error={
                        formik.touched.length &&
                        Boolean(formik.errors.length)
                    }
                    helperText={
                        formik.touched.length && formik.errors.length
                    }
                    style={{margin:'15px'}}
                    />    
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.deliverylat}
                    name="deliverylat"
                    onChange={formik.handleChange}
                    placeholder='Delivery Latitude'
                    error={
                        formik.touched.deliverylat &&
                        Boolean(formik.errors.deliverylat)
                    }
                    helperText={
                        formik.touched.deliverylat && formik.errors.deliverylat
                    }
                    style={{margin:'15px'}}
                    />
               <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.deliverylon}
                    name="deliverylon"
                    onChange={formik.handleChange}
                    placeholder='Delivery Longitude'
                    error={
                        formik.touched.deliverylon &&
                        Boolean(formik.errors.deliverylon)
                    }
                    helperText={
                        formik.touched.deliverylon && formik.errors.deliverylon
                    }
                    style={{margin:'15px'}}
                    />  
                     <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.width}
                    name="width"
                    onChange={formik.handleChange}
                    placeholder='Width'
                    error={
                        formik.touched.width &&
                        Boolean(formik.errors.width)
                    }
                    helperText={
                        formik.touched.width && formik.errors.width
                    }
                    style={{margin:'15px'}}
                    />    
            </div>
            <div className="AddFleet_form_field_wrapper"> 
            <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.volume}
                    name="volume"
                    onChange={formik.handleChange}
                    placeholder='Volume'
                    error={
                        formik.touched.volume &&
                        Boolean(formik.errors.volume)
                    }
                    helperText={
                        formik.touched.volume && formik.errors.volume
                    }
                    style={{margin:'15px'}}
                    />  
                     <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.weight}
                    name="weight"
                    onChange={formik.handleChange}
                    placeholder='Weight'
                    error={
                        formik.touched.weight &&
                        Boolean(formik.errors.weight)
                    }
                    helperText={
                        formik.touched.weight && formik.errors.weight
                    }
                    style={{margin:'15px'}}
                    />  
            </div>
            <div className="AddFleet_page_save_button_wrapper"> 
            {bookordermsg ?<div className='AddOrder_text_response_wrapper'> 
                <p> {bookordermsg}</p>
            </div>:null } 
              
            {isconfim ? <button onClick={handleconfirmorder}> Confirm Order</button>: <button type="submit"> Book Order</button>}
             
            </div>
        </form>   
       </div>
     </div>
     </div>
     <ToastContainer/> 
    </>
       )
}

export default AddOrder
