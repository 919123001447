import React from 'react'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link, useNavigate } from 'react-router-dom';
import LogOutModal from '../subcomponents/LogOutModal';


function Header() {
  
  const navigate= useNavigate() 



  const emailId = localStorage.getItem('useremail')
  const role =localStorage.getItem('role')
  return (
    <div className='Header_Main_whole_wrapper'>
        <div className="Header_logo_wrapper">
          
            <img onClick={()=>{
              navigate('/dashboard')
            }} src="/assets/images/Ottonomy Logo_White_With_TM-02.png"/>
       
       </div>
        <div className="Header_Right_side_content"> 
            <div> 
             <h5> {emailId}</h5>
             <p>  {role}</p> 
           </div>
           <div > 
           <LogOutModal/>
           </div>
      </div> 
    </div>
  )
}

export default Header
