import axios from "axios"

export const login=(values)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}login/v1/login`,{
         emailId:values?.email,
         password:values?.password
     }).then((res)=>{
        return res
      })
   }
   export const fleetlist=(email , token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListAndRobotStatusByUserEmail`,{
        emailId:email,
     },{
      headers:{
          'Authorization':`Bearer ${token}`,
          'Content-Type': 'application/json', 
      }
  }).then((res)=>{
        return res
      })
   }
  export const robotlist=(values, token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v2/getRobotList`,{
         emailId:values?.email,
         password:values?.password
     },{
      headers:{
          'Authorization':`Bearer ${token}`,
          'Content-Type': 'application/json', 
      }
  }).then((res)=>{
        return res
      })
   }

   export const getrobotStatus =(robotid , token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`, {
        robotId:robotid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
 }
 export const RobotModeById=(robotId,token, runningState)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`, {
      robotId:robotId,
      robotMode:runningState
  },{ headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
      })
}
export const DisengageMotors=(robotId,token,State)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`, {
      robotId:robotId,
      robotMode:State
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const getLocationList=(fleetid, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}location/v1/locationListByFleetId`, {
      fleetId:fleetid
  },{
        headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
 }

export const Openssh=(robotId,token ,sessionState)=>{
   return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/setSshSessionbyRobotId`, {
       robotId:robotId,
       sessionStatus : sessionState
   },{   headers:{
             'Authorization':`Bearer ${token}`,
             'Content-Type': 'application/json', 
         }
     }).then((res)=>{
       return res
   })
}

export const robotListByFleetId=(fleetId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/robotListByFleetId`, {
  fleetId: fleetId
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const robotactionapi=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`, {
      robotId : robotId,
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })   
}

export const UpdateRobotAction=(robotId,token,status,actionId,orderId)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}order/v1/completeAction`, {
      robotId : robotId,
      orderId: orderId,
      actionId: actionId,
      actionStatus: status
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const cancelOrderAPI=(id, fleetid, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}order/v1/cancelOrder`,{
         orderId: id,
         fleetId:fleetid
  }, {
        headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
 }
 export const handleteleopAckinterrupt=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopACK`, {
  robotId: robotId
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}

export const handleteleopComplete=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopCompleted`, {
  robotId: robotId
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const changeRobotWanderStatus=(robotId, token , wandermode)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/changeRobotWanderStatus`, {
      robotId : robotId,
      wanderStatus: "true" 
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const changeringlightStatus=(robotId, token , ringlight)=>{
  console.log('RingLight', ringlight)
  if(ringlight){
      return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`, {
          robotId : robotId,
          interruptAction: "DISABLE_RINGLIGHT" 
      },{   headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json', 
            }
        }).then((res)=>{
          return res
      })
  }
  else{
      return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`, {
          robotId : robotId,
          interruptAction: "ENABLE_RINGLIGHT" 
      },{   headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json', 
            }
        }).then((res)=>{
          return res
      })
  }
}
export const changeheadlightStatus=(robotId, token , headlight)=>{
  if(headlight){
      return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`, {
          robotId : robotId,
          interruptAction: "DISABLE_HEADLIGHT" 
      },{   headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json', 
            }
        }).then((res)=>{
          return res
      })
  }
  else{
      return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`, {
          robotId : robotId,
          interruptAction: "ENABLE_HEADLIGHT" 
      },{   headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json', 
            }
        }).then((res)=>{
          return res
      })
  }
}
export const sendhome=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/sendRobotHome`, {
      robotId:robotId
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const resetDiagnostics=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`, {
      robotId:robotId,
      robotMode:'RESET_DIAGNOSTICS'
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const opendoorone=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`, {
      robotId:robotId,
      compartment:"CABIN1"
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const opendoortwo=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`, {
      robotId:robotId,
      compartment:"CABIN2"
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const closedoorone=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`, {
      robotId:robotId,
      compartment:"CABIN1"
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const closedoortwo=(robotId, token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`, {
      robotId:robotId,
      compartment:"CABIN2"
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res   
  })
}
export const EmergencyStop=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/emergencyStop`, {
      robotId : robotId,
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const ReleaseEmergencyStop=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/releaseEmergencyStop`, {
      robotId : robotId,
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const pauserobot=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendPauseCommand`, {
      robotId : robotId,
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const playrobot=(robotId,token)=>{
  return axios.post(`${process.env.REACT_APP_BASE_URL}interrupt/v1/sendRunCommand`, {
      robotId : robotId,
  },{   headers:{
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/json', 
        }
    }).then((res)=>{
      return res
  })
}
export const addfleet=(values,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v2/addFleet`, {
        fleetName:values.fleetName,
        location:values.location,
        latitude:values.latitude,
        longitude:values.longitude
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  export const addRobot=(values,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v2/addRobot`, {
        fleetName:values.fleetName,
        location:values.location,
        latitude:values.latitude,
        longitude:values.longitude
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const addUser=(values,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}user/v2/addUser`, {
        emailId:values.emailId,
        password:values.password,
        firstName:values.firstName,
        lastName:values.lastName,
        contactNumber:values.contactNumber
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const userlist=(token)=>{
    return axios.get(`${process.env.REACT_APP_BASE_URL}user/v2/getUsersList`,{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  
  export const editfleet=(values,token,id)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v2/editFleet`, {
        fleetId:id,
        fleetName:values.fleetName,
        location:values.location,
        latitude:values.latitude,
        longitude:values.longitude
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  export const deletefleet=(id,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v2/deleteFleet`, {
        fleetId:id,
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  
  export const editUser=(values,token, id)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}user/v2/editUser`, {
        userId:id,
        firstName:values.firstName,
        lastName:values.lastName,
        emailId:values.emailId,
        contactNumber:values.contactNumber,
        pin:values.pin
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  export const deleteuser=(id,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}user/v2/deleteUser`, {
        userId:id,
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const orderlist=(fleetId,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}order/list`, {
      fleetId:fleetId,
      page:'1',
      pageSize:"10"
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const addorder=(values,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}order/booking`, {
        orderId: values.orderId,
        orderInfo: {
            size: values.orderInfoSize,
            dimension: {
                height: parseInt(values.height),
                length: parseInt(values.length),
                width:  parseInt(values.width),
                volume: parseInt(values.volume),
                weight: parseInt(values.weight)
            }
        },
        pickup: {
            lon: parseFloat(values.pickuplon),
            lat: parseFloat(values.pickuplat)
        },
        delivery: {
            lon: parseFloat(values.deliverylon),
            lat: parseFloat(values.deliverylat)
        }
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const orderConfirm=(OrderId,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}order/confirm`, {
     orderId:OrderId
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }

  export const sendOtpmail=(emailId,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}users/sendOtpOnMail`, {
        emailId:emailId
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
 
  
  export const verifyOtpMail=(emailId,otp,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}users/verifyOtp`, {
        emailId:emailId,
        otp:otp
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  export const changepassword=(emailId,newPassword,token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}users/forgetPassword`, {
        emailId:emailId,
        newPassword:newPassword
    },{   headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
  }
  
  export const cancelActionAPI=(orderId, fleetid,actionId, token)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}order/v1/cancelAction`,{
           orderId: orderId,
           fleetId:fleetid,
           actionId:actionId
    }, {
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
}