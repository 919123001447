import logo from './logo.svg';
import './App.css';
import Login from './components/Login';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import FleetPage from './subcomponents/FleetPage';
import RobotPage from './subcomponents/RobotPage';
import IndividualFleet from './subcomponents/IndividualFleet';
import RobotIndividual from './subcomponents/RobotIndividual';
import AddFleet from './subcomponents/AddFleet';
import AddRobot from './subcomponents/AddRobot';
import AddUser from './subcomponents/AddUser';
import UserList from './subcomponents/UserList';
import EditFleet from './subcomponents/EditFleet';
import EditRobot from './subcomponents/EditRobot';
import EditUser from './subcomponents/EditUser';
import IndividualUsers from './subcomponents/IndividualUsers';
import OrderList from './subcomponents/OrderList';
import AddOrder from './subcomponents/AddOrder';
import ForgotPassword from './components/ForgotPassword';
import OTPVerification from './components/OTPVerification';
import ChangePassword from './components/ChangePassword';

function App() {

  const dashboardopen=true
  return (
    <div className="App">
     <Routes> 
        <Route path="/" element={<Login/>}/>
        <Route path="/dashboard" element={<Dashboard dashboardopen={dashboardopen}/>}/>
        <Route path="/fleetPage" element={<FleetPage dashboardopen={dashboardopen}/>}/>
        <Route path="/robotpage" element={<RobotPage dashboardopen={dashboardopen}/>}/>
        <Route path="fleetPage/:fleetId" element={<IndividualFleet dashboardopen={dashboardopen}/>}/>
        <Route path="robotPage/:robotId" element={<RobotIndividual dashboardopen={dashboardopen}/>}/>
        <Route path="/addfleet" element={<AddFleet dashboardopen={dashboardopen}/>}/>
        <Route path="/addrobot" element={<AddRobot dashboardopen={dashboardopen}/>}/>
        <Route path="/adduser" element={<AddUser dashboardopen={dashboardopen}/>}/>
        <Route path="/userlist" element={<UserList dashboardopen={dashboardopen}/>}/>
        <Route path="editfleet/:fleetId" element={<EditFleet dashboardopen={dashboardopen}/>}/>
        <Route path="editrobot/:robotId" element={<EditRobot dashboardopen={dashboardopen}/>}/>
        <Route path="edituser/:userid" element={<EditUser dashboardopen={dashboardopen}/>}/>
        <Route path="userlist/:userId" element={<IndividualUsers dashboardopen={dashboardopen}/>}/>
        <Route path="/orderlist" element={<OrderList dashboardopen={dashboardopen}/>}/>
        <Route path="/addorder" element={<AddOrder dashboardopen={dashboardopen}/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword dashboardopen={dashboardopen}/>}/>
        <Route path="/otpverification" element={<OTPVerification dashboardopen={dashboardopen}/>}/>
        <Route path="/changepassword" element={<ChangePassword dashboardopen={dashboardopen}/>}/>
    </Routes> 
    </div>
  );
}

export default App;
