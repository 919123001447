import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { Form, Formik,useFormik } from 'formik';
import { Button, ButtonBase, Checkbox, FormControl, FormControlLabel, FormGroup, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import { useLocation, useNavigate } from 'react-router-dom';
// import { getFleetListAdmin, getFleetListUser, getFleetListuser, login } from '../API/index';

import { useDispatch } from 'react-redux';
// import { useAuth0 } from '@auth0/auth0-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changepassword, login } from '../API';
function ChangePassword() {
  const toastmsg=(msg)=> toast(msg)
 
//   const {
//     isLoading,
//     loginWithPopup,
//     loginWithRedirect,
//     error,
//     user,
//     logout,
//     isAuthenticated
//   } = useAuth0();
   const token = localStorage.getItem('token')
   const emailId = useLocation()
   const dispatch = useDispatch()
    const validationSchema = yup.object({
       password: yup.string().min(5, 'Password should be of minimum 5 characters length').required('Password is required'),
       confirmpassword:yup.string().oneOf([yup.ref('password'),null], 'Password must match').required('Confirm Password is Required') 
     });
   const navigate = useNavigate()
     const formik =useFormik({
       initialValues: {
         password: '',
         confirmpassword:'',
         showPassword:true,
         showPasswordtwo:true
       },
       validationSchema:validationSchema,
       onSubmit: (values) => {
         changepassword(emailId.state.email,values.password,token).then((res)=>{
            toastmsg(res.data.message)
            if(res.status===200 || res.data==="200"){
                // setTimeout(()=>{
                //     navigate('/')
                // },3000)
            }
         }).catch((err)=>{
            toastmsg(err.data.message)
        })
       
       }    
    })
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    return (


      <div className="DesktopMain-wrapper">    
         <div className='Login_page-wholeMain-wrapper'>  
        
          <div className='LoginPage-Main-wrapper'>
          <img src="/assets/images/Ottonomy Logo_White_With_TM-02.png" className='Login-page-form-logo-image'/>
          <form onSubmit={formik.handleSubmit} className="Login-page-form"> 
          {/* <p className="Login-form-p">Login To dashboard</p>
          <h6>Enter you credentials below </h6> */}
        <div className='Loginpage=-main-form'>  
        <div className="Login_page_input_wholeLabel_wrapper">   
            <InputLabel style={{textAlign:'left',color:'#979FB8',paddingLeft:'25px',marginBottom:'8px',fontSize:'15px'}}> Password</InputLabel>
             <div className="Loginpage-main-form-inner-div" >   
              <TextField
                sx={{borderRadius:'0px',width:"100%"}}
                fullWidth
                id="password"
                variant="standard"
                name="password"
                placeholder='Password'
                type={formik.values.showPassword? 'password':'text'}
                InputProps={{
                    // startAdornment: (
                    // <InputAdornment position="start">
                    // <LockIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    // </InputAdornment>
                    // ),
                    endAdornment:(
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{
                            formik.setFieldValue("showPassword",!formik.values.showPassword) 
                        // console.log('showPassword',formik.values.showPassword)
                       
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{color:'rgb(189,196,224)'}}
                    >
                        {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>  
                    )
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                /> 
            </div>  
        </div>   
        <div className="Login_page_input_wholeLabel_wrapper">   
            <InputLabel style={{textAlign:'left',color:'#979FB8',paddingLeft:'25px',marginBottom:'8px',fontSize:'15px'}}>Confirm Password</InputLabel>
             <div className="Loginpage-main-form-inner-div" >   
              <TextField
                sx={{borderRadius:'0px',width:"100%"}}
                fullWidth
                id="confirmpassword"
                variant="standard"
                name="confirmpassword"
                placeholder='Confirm Password'
                type={formik.values.showPasswordtwo? 'password':'text'}
                InputProps={{
                    // startAdornment: (
                    // <InputAdornment position="start">
                    // <LockIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    // </InputAdornment>
                    // ),
                    endAdornment:(
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{
                            formik.setFieldValue("showPasswordtwo",!formik.values.showPasswordtwo) 
                        // console.log('showPassword',formik.values.showPassword)
                       
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{color:'rgb(189,196,224)'}}
                    >
                        {formik.values.showPasswordtwo ? <VisibilityOff /> : <Visibility />}
                    </IconButton>  
                    )
                }}
                value={formik.values.confirmpassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
                helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                /> 
            </div>  
        </div>    
            
        </div>
        <div className='Loginpage-btn-wrapper'>
           <button type="submit" className='Loginpage-btn-wrapper-btn'> Reset Password</button>
        </div>  
       </form>
    </div>
    {/* {isAuthenticated && 
    (  <>
        {JSON.stringify(user,null,2)}
        </>
     )}
     */}
    </div>
    <ToastContainer/>
    </div> 

  )
}

export default ChangePassword